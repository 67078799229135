<template>
    <div class="chat_body__footer" ref="chatFooter">
        <ReplyMessage />
        <div class="input_wrapper">
            <a-textarea
                v-if="isMobile"
                class="message_input"
                :auto-size="{ minRows: 1, maxRows: 6} "
                v-model="message.text"
                ref="message_input"
                :placeholder="$t('chat.press_message')"
                @change="setTyping()" />
            <template v-else>
                <a-textarea
                    v-if="behaviorStatus"
                    class="message_input"
                    :auto-size="{ minRows: 1, maxRows: 15} "
                    v-model="message.text"
                    ref="message_input"
                    :placeholder="$t('chat.press_message')"
                    @change="setTyping()"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="sendMessage" />
                <a-textarea
                    v-else
                    class="message_input"
                    :auto-size="{ minRows: 1, maxRows: 15} "
                    v-model="message.text"
                    ref="message_input"
                    :placeholder="$t('chat.press_message')"
                    @change="setTyping()"
                    @keydown.enter.shift.exact.prevent
                    @keyup.enter="keydownHandler" />
            </template>
            <div class="send_actions">
                <template v-if="!isMobile && is_support">
                    <a-popover
                        trigger="click"
                        placement="topRight"
                        @visibleChange="popoverVisibleChange"
                        destroyTooltipOnHide>
                        <template slot="content">
                            <div class="popover-content" :style="{ height: popoverHeight + 'px' }">
                                <SupportMessageTemplates
                                    @changePopoverHeight="changePopoverHeight" />
                            </div>
                        </template>
                        <div class="act_btn ant-btn flex items-center justify-center ant-btn-ui ant-btn-circle ant-btn-icon-only ant-btn-background-ghost">
                            <i class="fi fi-rr-file-edit"></i>
                        </div>
                    </a-popover>
                </template>
                <Emoji 
                    v-if="!isMobile" 
                    class="act_btn"
                    @click="selectEmoji" />
                <label
                    class="act_btn ant-btn flex items-center justify-center ant-btn-ui ant-btn-circle ant-btn-icon-only ant-btn-background-ghost"
                    for="file_upload">
                    <i class="fi fi-rr-clip"></i>
                </label>
                <div class="send_button_wrap">
                    <a-button
                        class="send_button"
                        type="primary"
                        icon="fi-rr-paper-plane-top"
                        flaticon
                        shape="circle"
                        @click="sendMessage" />
                    <div v-if="!isMobile" class="send_behavior" @click="behaviorType()">
                        <template v-if="!behaviorStatus">Shift + </template>Enter
                    </div>
                </div>
            </div>
        </div>
        <input
            type="file"
            id="file_upload"
            multiple
            style="display:none;"
            ref="file_upload"
            @change="handleFileUpload"
            accept=".jpg, .jpeg, .png, .gif .doc, .docx, .xlsx, .xls, .pdf, .zip, .rar, .7z, .txt" />
        <a-modal
            @dragover.prevent
            @drop.prevent
            :title="$t('chat.file_modal_title')"
            centered
            :visible="fileModal"
            :zIndex="isMobile ? 5000 : 1000"
            :getContainer="getContainer"
            class="file_modal"
            @cancel="closeFileModal()">
            <div class="file_modal_body">
                <div v-if="fileList && fileList.length">
                    <div
                        v-for="(file, index) in fileList"
                        :key="index"
                        class="file_item relative">
                        <a-spin v-if="!file.file" />
                        <template v-else>
                            <div
                                v-if="file.image"
                                class="image_file">
                                <a-button
                                    icon="fi-rr-trash"
                                    flaticon
                                    shape="circle"
                                    @click="deleteFile(file)"
                                    class="absolute img_delete" />
                                <img
                                    :src="file.file.path"
                                    :alt="file.iid">
                            </div>
                            <div
                                v-else
                                class="mb-2 doc_item w-full flex items-center justify-between">
                                <div class="flex items-center truncate">
                                    <a-icon
                                        type="file"
                                        class="mr-2" />
                                    <div class="truncate">
                                        {{file.name}}
                                    </div>
                                </div>
                                <a-button
                                    @click="deleteFile(file)"
                                    class="ml-2"
                                    icon="delete"
                                    type="link" />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <ReplyMessageModal />
                <div class="input_wrapper">
                    <a-textarea
                        v-if="isMobile"
                        class="message_input"
                        :auto-size="{ minRows: 1, maxRows: 4} "
                        v-model="messageModal.text"
                        @change="setTyping()"
                        @keyup.esc.exact="closeFileModal"
                        ref="message_input_modal"
                        :placeholder="$t('chat.press_message')" />
                    <template v-else>
                        <a-textarea
                            v-if="behaviorStatus"
                            class="message_input"
                            :auto-size="{ minRows: 1, maxRows: 10} "
                            v-model="messageModal.text"
                            ref="message_input_modal"
                            :placeholder="$t('chat.press_message')"
                            @change="setTyping()"
                            @keydown.enter.exact.prevent
                            @keyup.enter.exact="sendMessage"
                            @keyup.esc.exact="closeFileModal" />
                        <a-textarea
                            v-else
                            class="message_input"
                            :auto-size="{ minRows: 1, maxRows: 15} "
                            v-model="messageModal.text"
                            ref="message_input"
                            :placeholder="$t('chat.press_message')"
                            @change="setTyping()"
                            @keydown.enter.shift.exact.prevent
                            @keyup.enter="keydownHandler"
                            @keyup.esc.exact="closeFileModal" />
                    </template>

                    <div class="send_actions">
                        <label
                            class="act_btn ant-btn flex items-center justify-center ant-btn-ui ant-btn-circle ant-btn-icon-only ant-btn-background-ghost"
                            for="file_upload_modal">
                            <i class="fi fi-rr-clip"></i>
                        </label>
                        <input
                            type="file"
                            id="file_upload_modal"
                            multiple
                            style="display:none;"
                            ref="file_upload_modal"
                            @change="handleFileUpload"
                            accept=".jpg, .jpeg, .png, .gif .doc, .docx, .xlsx, .xls, .pdf, .zip, .rar, .7z, .txt" />
                        <a-button
                            class="send_button"
                            type="primary"
                            icon="fi-rr-paper-plane-top"
                            flaticon
                            shape="circle"
                            @click="sendMessage" />
                    </div>
                </div>
            </div>
        </a-modal>
        <div v-show="showDragWin" class="drag-win">
            <div class="drag-body">
                <a-icon type="file-add"  :style="{ fontSize: '54px', color: '#0e4682'} " />
                <span class="text-xl" :style="`color:#0e4682`">Перетащите сюда файлы</span>
            </div>
        </div>
    </div>
</template>

<script>
import ReplyMessage from './ReplyMessage'
import ReplyMessageModal from './ReplyMessageModal'
import ChatEventBus from '../../utils/ChatEventBus'
import computed from './mixins/computed'
import methods from './mixins/methods'
import utils from './mixins/utils'
import Emoji from '@/components/Emoji'
import SupportMessageTemplates from './SupportMessageTemplates'
import eventBus from '@/utils/eventBus'
import { useResizeObserver } from '@vueuse/core'
export default {
    name: "ChatFooter",
    props: {
        resizeEvent: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        Emoji,
        ReplyMessage,
        ReplyMessageModal,
        SupportMessageTemplates
    },
    mixins: [computed, methods, utils],
    computed: {
        chatFooter() {
            return this.$refs.chatFooter
        }
    },
    data(){
        return {
            behaviorStatus: true,
            height: this.isMobile ? 50 : 55,
            popoverHeight: 318,
            showDragWin: false
        }
    },
    watch: {
        fileModal(val) {
            if(val) {
                this.$nextTick(() => {
                    this.$refs['message_input_modal']?.focus()
                })
            }
        }
    },
    created() {
        const behaviorType = localStorage.getItem('behaviorType')
        if(behaviorType) {
            this.behaviorStatus = JSON.parse(behaviorType)
        }
        eventBus.$on('pasteText', (text) => {
            this.message.text = text
        })
    },
    mounted() {
        ChatEventBus.$on('inputFocus', () => {
            this.$nextTick(() => {
                this.$refs['message_input']?.focus()
            })
        })

        this.$nextTick(()=>{
            if(this.activeChat){ 
                this.initListeners()
            }
        })  

        useResizeObserver(this.chatFooter, entries => {
            const entry = entries[0]
            const { height } = entry.contentRect
            if(height > this.height) {
                this.resizeEvent()
            }
        })
    },
    beforeDestroy() {
        this.removeListeners()
        eventBus.$off('pasteText')
    },
    methods: {
        popoverVisibleChange(value) {
            if(!value) {
                this.$store.commit('chat/RESET_SUPPORT_MESSAGE_TEMPLATES')
                this.$store.commit('chat/RESET_SMT_PAGE')
                this.$store.commit('chat/SET_SMT_END_OF_LIST', false)
                this.popoverHeight = 318
            }
        },
        changePopoverHeight(value) {
            this.$nextTick(() => {
                this.popoverHeight = value
            })
        },
        initListeners() {
            window.addEventListener('paste', this.paste)
            window.addEventListener('dragover', this.dragOver)
            window.addEventListener('drop', this.dropComplete)
            window.addEventListener('dragleave', this.dragLeave)
        },
        removeListeners() {
            window.removeEventListener('paste', this.paste, false)
            window.removeEventListener('dragover', this.dragOver, false)
            window.removeEventListener('drop', this.dropComplete, false)
            window.removeEventListener('dragleave', this.dragLeave, false)
        }
    }
}
</script>

<style lang="scss" scoped>
.send_button_wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.send_behavior{
    font-size: 9px;
    color: var(--gray);
    cursor: pointer;
    margin-top: 1px;
    min-width: 49px;
    text-align: center;
    &:hover{
        opacity: 0.8;
    }
}
</style>

<style lang="scss">

.file_modal_body{
    overflow-y: auto;
    padding: 10px;
    .img_delete{
        top: 10px;
        right: 10px;
    }
    .file_item{
        &:not(:last-child){
            .image_file{
                margin-bottom: 10px;
            }
        }
        .image_file{
            background: rgba(0, 0, 0, 0.04);
            position: relative;
            min-height: 200px;
            max-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img{
                width: 100%;
                object-fit: cover;
                vertical-align: middle;
                -o-object-fit: cover;
            }
        }
    }
}
.file_modal{
    .ant-modal-header{
        padding: 10px;
    }
    .ant-modal-footer{
        padding: 0px;
        position: sticky;
        bottom: 0;
        z-index: 10;
    }
    .ant-modal-close-x{
        width: 43px;
        height: 43px;
        line-height: 43px;
    }
    .input_wrapper{
        position: relative;
        .message_input{
            width: 100%;
            height: 100%;
            text-align: left;
            min-height: 55px!important;
            outline: none;
            padding: 10px 90px 10px 15px;
            border: 0px;
            outline: none;
            &:focus,
            &:hover{
                border: 0px;
                box-shadow: initial;
            }
        }
        .send_actions{
            position: absolute;
            bottom: 0;
            z-index: 1;
            right: 0;
            height: 55px;
            display: flex;
            align-items: center;
            padding-right: 10px;
            .act_btn{
                margin-right: 8px;
                i{
                    font-size: 20px;
                }
            }
        }
    }
    .ant-modal-body{
        padding: 0px;
        min-height: 400px;
        max-height: 400px;
        overflow-y: auto;
        @media (max-width: 900px) {
            min-height: auto;
            max-height: 300px;
        }
    }
    @media (min-width: 901px) {
        .ant-modal-wrap,
        .ant-modal-mask{
            position: absolute;
        }
    }
    .ant-modal-mask{
        background-color: rgba(0, 0, 0, 0.3);
    }
}
.drag-win{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
    bottom: 0;
    z-index: 99;
    .drag-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 3px dashed var(--primaryColor);
        border-radius: 20px;
        width: 96%;
        height: 80%;
        margin-right: 1%;
    }
}
</style>