export default class FormValidation {
    constructor(title='', text='') {
        this.title = title
        this.text = text
    }
    validate() {
        let errors = {}
        
        if(!this.titleValidate()) {
            errors['title'] = 'Поле \"Название\" не может быть пустым'
        }
        if(!this.textValidate()) {
            errors['text'] = 'Поле \"Сообщение\" не может быть пустым'
        }
        
        return errors
    }
    titleValidate() {
        return this.title.length > 0 ? true : false
    }
    textValidate() {
        return this.text.length > 0 ? true : false
    }
}